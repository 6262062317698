<template>
  <div>
   <h1 class="text-18 text-gray-700 font-bold mb-4 mt-10">Get Insurance</h1>

    <form @submit.prevent="submit">
      
      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

    </form>

  </div>
</template>

<script>
export default {
  props: {
    services: {
      type: Array,
      default: () => []
    },
    provider: {
      type: String,
    }
  },
  data() {
    return {
      form: this.$options.basicForm([])
    }
  },
  methods: {
    async submit() {
      if (!this.validateUser('onboarding')) {
        this.$emit('close');
        return this.resumeOnboarding();
      }
      
      if (!this.validateForm(this.form)) {
        return false;
      }

      var request, data;

      switch (this.provider) {
        case 'flulterwave':
          break;
        case 'capricorn':
          break;
        default:
          return false;
      }

      this.form.loading = true;

      this.sendRequest(request, {
        data,
        success: response => {
          this.$emit('success', { service: 'insurance', response });
        },
        error: error => {
          this.form.error = error;
          this.mapFormError(this.form, error?.response?.data?.errors);
        }
      })

      this.form.loading = false;

    }
  }
}
</script>
