<template>
  <div>
    <h1 class="text-20 text-gray-700 font-bold mb-3">Pay Electricity Bills</h1>

    <form @submit.prevent="submit">
      
      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <form-group
        type="select"
        :options="serviceOptions"
        :left-icon="false"
        :form="form"
        name="provider"
        v-model="form.data.provider.value"
      >
        Network Provider
      </form-group>

      <form-group
        :left-icon="false"
        :form="form"
        name="meter_number"
        v-model="form.data.meter_number.value"
      >
        Meter Number
      </form-group>

      <form-group
        :left-icon="false"
        :form="form"
        name="phone"
        v-model="form.data.phone.value"
      >
        Phone Number
      </form-group>

      <form-group
        :left-icon="false"
        :form="form"
        name="amount"
        v-model="form.data.amount.value"
      >
        Amount
      </form-group>

      <repayment-cards @change="selectMethod" :key="componentKey"/>

      <div class="flex justify-center -mb-5">
        <button type="submit" class="btn btn-blue px-20 py-4" :disabled="form.loading">
          {{ form.loading ? 'Purchasing' : 'Purchase' }}
        </button>
      </div>

    </form>

  </div>
</template>

<script>
export default {
  props: {
    services: {
      type: Array,
      default: () => []
    },
    provider: {
      type: String,
    }
  },
  data() {
    return {
      form: this.$options.basicForm([
        'meter_number', 'provider', 'phone', 'amount',
        { name: 'authorization_code', rules: 'nullable' },
        { name: 'account_id', rules: 'nullable' },
        { name:'payment_method', value: 'repayment_card' }
      ]),
      componentKey: 0
    }
  },
  computed: {
    serviceOptions() {
      return this.services.map(service => service.select_option);
    },
    selectedService() {
      switch (this.provider) {
        case 'flulterwave':
          return this.services.find(service => service.biller_name === this.form.data.provider.value);
        case 'capricorn':
        return this.services.find(service => service.id === this.form.data.provider.value);
        default:
          return null;
      }
    },
  },
  methods: {
    selectMethod(paymentMethod) {
      this.form.data.payment_method.value = paymentMethod.method;
      switch (paymentMethod.method) {
        case 'repayment_card':
          this.form.data.authorization_code.value = paymentMethod.data.authorization_code;
          this.form.data.account_id.value = this.user?.personal_account?.id;
          break;
        case 'credpal_card':
          this.form.data.authorization_code.value = '';
          this.form.data.account_id.value = paymentMethod.account_id;
          break;
      }
    },
    async verifyMeterNumber() {
      var request, data;

      switch (this.provider) {
        case 'flulterwave':
          break;
        case 'capricorn':
          request = 'personal.bills.capricorn.verifyMeterNumber';
          data = {
            account_number: this.form.data.meter_number.value,
            service_type: this.selectedService?.serviceType
          };
          break;
        default:
          return false;
      }


      this.form.loading = true;
      var { error, response } = await this.sendRequest(request, {
        data
      })
      this.form.loading = false;

      if (error) {
        this.form.error = error;
        return false;
      }else if (response?.status === 200) {
        return true;
      }
    },
    async submit() {
      if (!this.validateUser('onboarding')) {
        this.$emit('close');
        return this.resumeOnboarding();
      }

      if (!this.validateForm(this.form)) {
        return false;
      }

      if (!await this.verifyMeterNumber()) {
        return false;
      }

      var request, data;

      switch (this.provider) {
        case 'flulterwave':
          break;
        case 'capricorn':
          request = 'personal.bills.capricorn.buyElectricity';
          data = {
            service_type: this.selectedService?.serviceType,
            account_number: this.form.data.meter_number.value,
            phone: this.form.data.phone.value,
            amount: this.form.data.amount.value,
            payment_method: this.form.data.payment_method.value,
            authorization_code: this.form.data.authorization_code.value,
            user_type: 'personal',
            account_id: this.form.data.account_id.value
          };
          break;
        default:
          return false;
      }

      this.form.loading = true;

      await this.sendRequest(request, {
        data,
        success: response => {
          this.form = this.resetForm(this.form);
          this.componentKey += 1
          this.$emit('success', { service: 'electricity', response });
        },
        error: error => {
          this.form.error = error;
          this.mapFormError(this.form, error?.response?.data?.errors);
        }
      })

      this.form.loading = false;

    }
  }
}
</script>
