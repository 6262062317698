<template>
  <div>
    <h1 class="text-20 text-gray-700 font-bold mb-3">Pay For Cable</h1>

    <form @submit.prevent="submit">
      
      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <form-group
        type="select"
        :options="serviceOptions"
        :left-icon="false"
        :form="form"
        name="provider"
        v-model="form.data.provider.value"
        :loading="subServicesResource.loading"
      >
        Network Provider
      </form-group>

      <form-group
        type="select"
        :options="planOptions"
        :left-icon="false"
        :form="form"
        name="plan"
        v-model="form.data.plan.value"
        :disabled="!form.data.provider.value"
        :loading="subServicesResource.loading"
      >
        Bundle
      </form-group>

      <form-group
        type="select"
        :options="durationOptions"
        :left-icon="false"
        :form="form"
        name="duration"
        v-model="form.data.duration.value"
        :disabled="!form.data.plan.value"
        :loading="subServicesResource.loading"
      >
        Select Duration
      </form-group>

      <form-group
        :left-icon="false"
        :form="form"
        name="account_number"
        v-model="form.data.account_number.value"
        :loading="subServicesResource.loading"
      >
        Smart Card Number
      </form-group>

      <repayment-cards @change="selectMethod" :key="componentKey"/>

      <div class="flex justify-center -mb-5">
        <button type="submit" class="btn btn-blue px-20 py-4" :disabled="form.loading">
          {{ form.loading ? 'Purchasing' : 'Purchase' }}
        </button>
      </div>

    </form>

  </div>
</template>

<script>
export default {
  props: {
    services: {
      type: Array,
      default: () => []
    },
    subServices: {
      type: [Array, Object],
      default: () => null
    },
    subServicesResource: {
      type: [Array, Object],
      default: () => null
    },
    provider: {
      type: String,
    }
  },
  data() {
    return {
      form: this.$options.basicForm([
        'account_number', 'provider', 'plan', 'duration',
        { name: 'authorization_code', rules: 'nullable' },
        { name: 'account_id', rules: 'nullable' },
        { name:'payment_method', value: 'repayment_card' }
      ]),
      componentKey: 0
    }
  },
  computed: {
    serviceOptions() {
      return this.services.map(service => service.select_option);
    },
    selectedService() {
      switch (this.provider) {
        case 'flulterwave':
          return this.services.find(service => service.biller_name === this.form.data.provider.value);
        case 'capricorn':
        return this.services.find(service => service.id === this.form.data.provider.value);
        default:
          return null;
      }
    },
    planOptions() {
      var plans = [];

      switch (this.subServices?.constructor) {
        case Object:
          return this.subServices?.[this.selectedService?.serviceType]?.map( plan => plan.select_option );
        default:
          break;
      }

      return plans;
    },
    selectedPlan() {
      switch (this.provider) {
        case 'flulterwave':
          return null;
        case 'capricorn':
        return this.subServices?.[this.selectedService?.serviceType]?.find((subService) => {
          return subService.code === this.form.data.plan.value;
        });
        default:
          return null;
      }
    },
    durationOptions() {
      return this.selectedPlan?.availablePricingOptions?.map(pricing => {
        return {
          title: `${pricing.monthsPaidFor} ${this.$options.filters.toPlural('Month', pricing.monthsPaidFor)}
            - 
            ${this.$options.filters.currency(pricing.price)}
          `,
          value: pricing.monthsPaidFor
        }
      })
    },
    selectedDuration() {
      return this.selectedPlan?.availablePricingOptions?.find(pricing => {
        return pricing.monthsPaidFor === this.form.data.duration.value
      });
    }
  },
  "form.data.provider.value"() {
    this.form.data.plan.value == '';
  },
  "form.data.plan.value"() {
    this.form.data.duration.value == '';
  },
  methods: {
    selectMethod(paymentMethod) {
      this.form.data.payment_method.value = paymentMethod.method;
      switch (paymentMethod.method) {
        case 'repayment_card':
          this.form.data.authorization_code.value = paymentMethod.data.authorization_code;
          this.form.data.account_id.value = this.user?.personal_account?.id;
          break;
        case 'credpal_card':
          this.form.data.authorization_code.value = '';
          this.form.data.account_id.value = paymentMethod.account_id;
          break;
      }
    },
    async verifyCableTvAccount() {
      var request, data;

      switch (this.provider) {
        case 'flulterwave':
          break;
        case 'capricorn':
          request = 'personal.bills.capricorn.verifyCableTvAccount';
          data = {
            account_number: this.form.data.account_number.value,
            service_type: this.selectedService?.serviceType
          };
          break;
        default:
          return false;
      }


      this.form.loading = true;
      const { error, response } = await this.sendRequest(request, {
        data
      })
      this.form.loading = false;

      if (error) {
        this.form.error = error;
        return false;
      }else if (response?.status === 200) {
        return true;
      }
    },
    async submit() {
      if (!this.validateUser('onboarding')) {
        this.$emit('close');
        return this.resumeOnboarding();
      }
      
      if (!this.validateForm(this.form)) {
        return false;
      }

      if (!await this.verifyCableTvAccount()) {
        return false;
      }

      let request, data;

      switch (this.provider) {
        case 'flulterwave':
          break;
        case 'capricorn':
          request = 'personal.bills.capricorn.buyCableTvBundle';
          data = {
            service_type: this.selectedService?.serviceType,
            product_code: this.form.data.plan.value,
            total_amount: this.selectedDuration?.price,
            product_monthsPaidFor: this.form.data.duration.value,
            smartcard_number: this.form.data.account_number.value,
            payment_method: this.form.data.payment_method.value,
            authorization_code: this.form.data.authorization_code.value,
            user_type: 'personal',
            account_id: this.form.data.account_id.value
          };
          break;
        default:
          return false;
      }

      this.form.loading = true;

      await this.sendRequest(request, {
        data,
        success: response => {
          this.form = this.resetForm(this.form);
          this.componentKey += 1
          this.$emit('success', { service: 'cable', response });
        },
        error: error => {
          this.form.error = error;
          this.mapFormError(this.form, error?.response?.data?.errors);
        }
      })

      this.form.loading = false;

    }
  }
}
</script>
