<template>
  <div class="pt-6">
    <div>
      <div>
        <h1 class="text-18 text-gray-700 font-bold mb-10">
          Airtime &amp; Bills
        </h1>

        <div class="flex flex-wrap gap-6 mb-10">
          <div
            class="border border-ash-900 rounded h-113px w-113px flex flex-col justify-center items-center text-center cursor-pointer"
            @click="selectCategory('airtime')"
          >
            <vector
              :src="assets.icons.sm.mobile_top_up"
              class="mb-5"
              height="50"
            />
            <div class="text-12 text-gray-800">Buy Airtime</div>
          </div>

          <div
            class="border border-ash-900 rounded h-113px w-113px flex flex-col justify-center items-center text-center cursor-pointer"
            @click="selectCategory('internet')"
          >
            <vector :src="assets.icons.sm.internet" class="mb-5" height="50" />
            <div class="text-12 text-gray-800">Internet &amp; Data</div>
          </div>

          <div
            class="border border-ash-900 rounded h-113px w-113px flex flex-col justify-center items-center text-center cursor-pointer"
            @click="selectCategory('electricity')"
          >
            <vector :src="assets.icons.sm.lightbulb" class="mb-5" height="50" />
            <div class="text-12 text-gray-800">Electricity Bills</div>
          </div>

          <div
            class="border border-ash-900 rounded h-113px w-113px flex flex-col justify-center items-center text-center cursor-pointer"
            @click="selectCategory('cable')"
          >
            <vector :src="assets.icons.sm.cable" class="mb-5" height="50" />
            <div class="text-12 text-gray-800">Cable TV</div>
          </div>

          <router-link
            :to="{ name: 'marketplace' }"
            class="border border-ash-900 rounded h-113px w-113px flex flex-col justify-center items-center text-center cursor-pointer"
          >
            <vector :src="assets.icons.sm.others" class="mb-2" height="60" />
            <div class="text-12 text-gray-800">Others</div>
          </router-link>

          <!-- <div
            class="border border-blue-200 rounded p-4 flex flex-col justify-center items-center text-center cursor-pointer"
            :class="{'border-blue-500 shadow-lg': category === 'insurance'}"
            @click="selectCategory('insurance')"
          >
            <vector :src="assets.icons.sm.insurance" class="mb-5" height="50" />
            <div class="text-sm text-gray-700">Insurance</div>
          </div>

          <div
            class="border border-blue-200 rounded p-4 flex flex-col justify-center items-center text-center cursor-pointer"
            :class="{'border-blue-500 shadow-lg': category === 'flight'}"
            @click="selectCategory('flight')"
          >
            <vector :src="assets.icons.sm.flight" class="mb-5" height="50" />
            <div class="text-sm text-gray-700">Flight Tickets</div>
          </div> -->
        </div>

        <div class="grid grid-cols-2 gap-4 mb-10">
          <div class="col-span-1 flex items-center">
            <h1 class="text-18 font-bold">Bills History</h1>
          </div>
          <!-- <div class="col-span-1">
            <search-form v-model="transactions.query" @submit="$refs.table.loadAjaxData" />
          </div> -->
        </div>

        <div class="w-full md:w-7/12 lg:w-7/12">
          <datatable
            :url="`${this.$baseurl}/baxi/transaction/${this.user.id}`"
            :ajax="true"
            :ajaxPagination="true"
            :columns="transactions.columns"
            :query="transactions.query"
            :loading="transactions.loading"
            :showHeadings="false"
            :onClick="click"
            :fillable="false"
            :limit="15"
            table-style="flush"
            ref="table"
          />
        </div>
      </div>

      <modal5
        className="w-full md:w-443px lg:w-443px flex flex-col rounded"
        ref="billsModal"
      >
        <template v-if="flutterwaveServices.loading || baxiServices.loading">
          <sm-loader></sm-loader>
        </template>
        <div
          class="alert alert-red-soft"
          v-else-if="
            getFormError(baxiServices) || getFormError(flutterwaveServices)
          "
        >
          <span class="alert-icon">!</span>
          <span>{{
            getFormError(baxiServices) || getFormError(flutterwaveServices)
          }}</span>
        </div>
        <component
          v-else
          :is="category"
          :services="services"
          :provider="provider"
          :sub-services="subServices"
          :sub-services-resource="subServicesResource"
          @close="cancelTransaction"
          @success="success"
        />
      </modal5>

      <modal5
        className="w-11/12 md:w-443px lg:w-443px flex flex-col rounded"
        ref="transactionModal"
      >
        <div class="flex flex-col w-full" v-if="transactionDetails">
          <div class="flex flex-row">
            <div class="flex flex-col ml-5 mt-1">
              <h4 class="text-18 text-gray-700 font-semibold">
                {{ transactionDetails.description }}
              </h4>
              <p class="text-12 mt-1 text-ash-800 font-thin">
                {{ transactionDetails.created_at | dateFormat("M dd") }} At
                {{ transactionDetails.created_at | dateFormat("h:ia") }}
              </p>
            </div>
          </div>
          <div class="mt-6 ml-5">
            <p
              class="text-13 mb-2 text-gray-800 font-thin tracking-wide leading-loose text-left"
            >
              <span class="font-bold">Amount:</span> N{{
                transactionDetails.amount | currency
              }}
            </p>
            <p
              class="text-13 text-gray-800 font-thin tracking-wide leading-loose text-left mb-2"
            >
              <span class="font-bold">Payment Method:</span>
              {{ transactionDetails.payment_method | sentenceCase | fromSlug }}
            </p>

            <p
              class="text-13 text-gray-800 font-thin tracking-wide leading-loose text-left"
              v-if="transactionDetails.recipient_number"
            >
              <span class="font-bold">Recipient Number:</span>
              {{ transactionDetails.recipient_number }}
            </p>
          </div>
        </div>
      </modal5>
    </div>

  </div>
</template>

<script>
export default {
  components: {
    airtime: require("./Airtime").default,
    cable: require("./Cable").default,
    internet: require("./Data").default,
    electricity: require("./Electricity").default,
    flight: require("./Flight").default,
    insurance: require("./Insurance").default,
  },
  data() {
    return {
      category: "airtime",
      transactionDetails: null,
      flutterwaveServices: this.$options.resource([]),
      baxiServices: this.$options.resource([]),
      baxiDataPlans: this.$options.resource({}),
      baxiCableTvPlans: this.$options.resource({}),
      form: this.$options.basicForm(["recipient", "amount"]),
      transactions: this.$options.resource([], {
        query: "",
        columns: [
          {
            name: "type",
            th: "Transaction",
            className: "w-full whitespace-normal",
            render: (transaction, type) => `
                <div class="text-gray-700 font-medium text-sm">${this.$options.filters.sentenceCase(
                  this.$options.filters.fromSlug(type)
                )}</div>
                <div class="text-gray-500 text-xs">${this.$options.filters.dateFormat(
                  transaction.created_at,
                  "M dd"
                ) +
                  " at " +
                  this.$options.filters.dateFormat(
                    transaction.created_at,
                    "h:ia"
                  )}</div>
              `,
          },
          {
            align: "right",
            name: "status",
            th: "Status",
            render: (transaction) => {
              var amount;

              if (transaction.amount > 0) {
                amount =
                  `- ` + this.$options.filters.currency(transaction.amount);
              } else {
                amount =
                  `+ ` +
                  this.$options.filters.currency(transaction.amount * -1);
              }

              return `
                  <div class="text-gray-700 font-medium text-sm">${amount}</div>
                  <div class="text-gray-500 text-xs flex items-center justify-end">
                    <span class="w-5px h-5px rounded-50 bg-green-500 inline-block mr-2"></span>
                    <span>Success</span>
                  </div>
                `;
            },
          },
          /*{
              th: 'Amount',
              name: 'amount',
            },
            {
              th: 'Payment Method',
              name: 'payment_method',
              render: (transaction, payment_method) => this.$options.filters.sentenceCase(this.$options.filters.fromSlug(payment_method))
            },
            {
              th: 'Status',
              name: 'status',
              render: (transaction, status) => {
                switch (status) {
                  case 'success':
                    return '<div class="badge badge-green-soft-outline">Success</div>'
                  default:
                    return status;
                }
              }
            },*/
          // {
          //   th: 'Date',
          //   name: 'created_at',
          //   render: (transaction, date) => this.$options.filters.dateFormat(date, 'D, dd M Y, h:ia')
          // },
        ],
      }),
    };
  },
  computed: {
    capricornAirtimeServices() {
      return this.baxiServices.data
        .filter((service) => service.serviceCategory === "airtime")
        .map((service) => ({
          ...service,
          select_option: { title: service.serviceName, value: service.id },
        }));
    },
    capricornCableTvServices() {
      return this.baxiServices.data
        .filter((service) => service.serviceCategory === "cabletv")
        .map((service) => ({
          ...service,
          select_option: { title: service.serviceName, value: service.id },
        }));
    },
    capricornDataServices() {
      return this.baxiServices.data
        .filter((service) => service.serviceCategory === "databundle")
        .map((service) => ({
          ...service,
          select_option: { title: service.serviceName, value: service.id },
        }));
    },
    capricornEpinServices() {
      return this.baxiServices.data
        .filter((service) => service.serviceCategory === "epin")
        .map((service) => ({
          ...service,
          select_option: { title: service.serviceName, value: service.id },
        }));
    },
    capricornPowerServices() {
      return this.baxiServices.data
        .filter((service) => service.serviceCategory === "electricity")
        .map((service) => ({
          ...service,
          select_option: { title: service.serviceName, value: service.id },
        }));
    },
    flutterwaveAirtimeServices() {
      return this.flutterwaveServices.data
        .filter(
          (service) =>
            service.biller_name.match(/airtime/i) && service.country == "NG"
        )
        .map((service) => ({
          ...service,
          select_option: { title: service.name, value: service.biller_name },
        }));
    },
    flutterwaveCableTvServices() {
      return this.flutterwaveServices.data
        .filter(
          (service) =>
            service.biller_name.match(/dstv/i) && service.country == "NG"
        )
        .map((service) => ({
          ...service,
          select_option: { title: service.name, value: service.biller_name },
        }));
    },
    flutterwaveDataServices() {
      return [].map((service) => ({
        ...service,
        select_option: { title: service.name, value: service.biller_name },
      }));
    },
    flutterwaveEpinServices() {
      return [].map((service) => ({
        ...service,
        select_option: { title: service.name, value: service.biller_name },
      }));
    },
    flutterwavePowerServices() {
      return this.flutterwaveServices.data
        .filter(
          (service) =>
            service.biller_name.match(/ekedc/i) && service.country == "NG"
        )
        .map((service) => ({
          ...service,
          select_option: { title: service.name, value: service.biller_name },
        }));
    },
    provider() {
      switch (this.category) {
        case "airtime":
          return "capricorn";
        case "cable":
          return "capricorn";
        case "internet":
          return "capricorn";
        case "electricity":
          return "capricorn";
        case "flight":
          return "";
        case "insurance":
          return "";
        default:
          return "";
      }
    },
    services() {
      switch (this.category) {
        case "airtime":
          return this.provider == "capricorn"
            ? this.capricornAirtimeServices
            : this.provider == "flutterwave"
            ? this.flutterwaveAirtimeServices
            : [];
        case "cable":
          return this.provider == "capricorn"
            ? this.capricornCableTvServices
            : this.provider == "flutterwave"
            ? this.flutterwaveCableTvServices
            : [];
        case "internet":
          return this.provider == "capricorn"
            ? this.capricornDataServices
            : this.provider == "flutterwave"
            ? this.flutterwaveDataServices
            : [];
        case "electricity":
          return this.provider == "capricorn"
            ? this.capricornPowerServices
            : this.provider == "flutterwave"
            ? this.flutterwavePowerServices
            : [];
        case "flight":
          return [];
        case "insurance":
          return [];
        default:
          return [];
      }
    },
    subServices() {
      switch (this.category) {
        case "airtime":
          return [];
        case "cable":
          return this.provider == "capricorn"
            ? this.baxiCableTvPlans.data
            : this.provider == "flutterwave"
            ? []
            : [];
        case "internet":
          return this.provider == "capricorn"
            ? this.baxiDataPlans.data
            : this.provider == "flutterwave"
            ? []
            : [];
        case "electricity":
          return [];
        case "flight":
          return [];
        case "insurance":
          return [];
        default:
          return [];
      }
    },
    subServicesResource() {
      switch (this.category) {
        case "airtime":
          return [];
        case "cable":
          return this.provider == "capricorn"
            ? this.baxiCableTvPlans
            : this.provider == "flutterwave"
            ? null
            : null;
        case "internet":
          return this.provider == "capricorn"
            ? this.baxiDataPlans
            : this.provider == "flutterwave"
            ? null
            : null;
        case "electricity":
          return null;
        case "flight":
          return null;
        case "insurance":
          return null;
        default:
          return null;
      }
    },
  },
  beforeMount() {
    // this.getFlutterwaveCategories();
    this.getCapricornCategories();
    this.getTransactions();
  },
  watch: {
    capricornDataServices(value) {
      value?.forEach((network) => {
        this.getCapricornDataPlans(network.serviceType);
      });
    },
    capricornCableTvServices(value) {
      value?.forEach((provider) => {
        this.getCapriconCableTvPlans(provider.serviceType);
      });
    },
  },
  methods: {
    cancelTransaction() {
      this.$refs.billsModal.close();
    },
    click(selected) {
      this.transactionDetails = selected;
      this.$refs.transactionModal.open();
      console.log(selected);
    },
    selectCategory(category) {
      this.category = category;
      this.$refs.billsModal.open();
    },
    success(data) {
      data;
      setTimeout(() => {
        this.getTransactions();
      }, 5000);
      this.$refs.billsModal.close();
      this.$success({
        title: 'Transaction Successful',
        button: 'Okay',
      });
    },

    async getFlutterwaveCategories() {
      this.flutterwaveServices.loading = true;
      await this.sendRequest(`personal.bills.flutterwave.categories`, {
        success: (response) => {
          this.flutterwaveServices.data = response.data.data;
        },
        error: (error) => {
          this.flutterwaveServices.error = error;
        },
      });
      this.flutterwaveServices.loading = false;
    },
    async getCapricornCategories() {
      this.baxiServices.loading = true;
      await this.sendRequest(`personal.bills.capricorn.categories`, {
        success: (response) => {
          this.baxiServices.data = response.data.data;
        },
        error: (error) => {
          this.baxiServices.error = error;
        },
      });
      this.baxiServices.loading = false;
    },
    async getCapricornDataPlans(network) {
      this.baxiDataPlans.loading = true;
      await this.sendRequest(`personal.bills.capricorn.dataPlans`, network, {
        success: (response) => {
          this.baxiDataPlans.data[network] = response.data.data?.map((plan) => {
            return {
              ...plan,
              select_option: {
                title: `${plan.name} - ₦${this.$options.filters.currency(
                  plan.price
                )}`,
                value: plan.datacode,
              },
            };
          });
        },
        error: (error) => {
          this.baxiDataPlans.error = error;
        },
      });
      this.baxiDataPlans.loading = false;
    },
    async getCapriconCableTvPlans(provider) {
      this.baxiCableTvPlans.loading = true;
      await this.sendRequest(
        `personal.bills.capricorn.cableTvPlans`,
        provider,
        {
          success: (response) => {
            this.baxiCableTvPlans.data[provider] = response.data.data?.map(
              (plan) => {
                return {
                  ...plan,
                  select_option: {
                    title: plan.name,
                    value: plan.code,
                  },
                };
              }
            );
          },
          error: (error) => {
            this.baxiCableTvPlans.error = error;
          },
        }
      );
      this.baxiCableTvPlans.loading = false;
    },
    async getTransactions() {
      this.transactions.loading = true;
      await this.sendRequest("personal.bills.capricorn.transactions", {
        success: (response) => {
          this.transactions.data = response.data.data;
        },
        error: (error) => {
          this.transactions.error = error;
        },
      });
      this.transactions.loading = false;
    },
  },
};
</script>